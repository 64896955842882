<template>
  <header class="MenuWidget">
    <simple-spinner :show="isLoading" />

    <div
      class="widget-menu"
      v-show="!isLoading"
      @focusout="closeDropdown"
      tabindex="-1"
    >
      <button
        class="widget-menu-btn widget-menu-btn-space-right"
        type="button"
        @click="toggleDropdown"
      >
        <i class="icon" :class="mainItem.icon" />

        <span>{{ mainItem.title }}</span>

        <i
          v-show="services.length"
          class="caret"
          :class="{ 'is-flipped': isDropdownOpen }"
        />
      </button>
    </div>

    <transition name="show-from-top">
      <service-dropdown
        v-if="isDropdownOpen && services.length"
        :services="services"
        :is-open="isDropdownOpen"
      />
    </transition>

    <div class="widget-right">
      <div
        v-if="!isLoading && displayedUsername.length > 0"
        class="widget-name icon-with-text has-switching-border"
        @focusout="closeMenu"
        tabindex="-1"
      >
        <button
          :title="displayedUsername"
          @click="toggleMenu"
          class="widget-menu-btn"
        >
          <i class="icon icon-user" />
          <span class="icon-text" v-text="displayedUsername" />
        </button>

        <transition name="show-from-top">
          <div class="user-menu is-dropdown list" v-show="isMenuOpen">
            <slot
              ><li><a href="https://my.medel.com">myMED-EL</a></li></slot
            >
          </div>
        </transition>
      </div>

      <a
        v-if="!isLoading && displayedUsername.length === 0"
        :href="loginUrl"
        class="widget-login icon-with-text has-switching-border"
      >
        <i class="icon icon-key" />
        Login
      </a>

      <a class="widget-logo" :href="myMedelUrl">
        <img
          alt="myMED-EL"
          src="https://widgets.my.medel.com/menu/medel-logo.svg"
        />
      </a>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import SimpleSpinner from "./SimpleSpinner";
import ServiceDropdown from "./ServiceDropdown";

export default {
  name: "TopBarServices",

  props: {
    defaultText: {
      type: String,
      default: "Portal"
    },
    language: {
      type: String,
      default: "en"
    },
    portalId: {
      type: String,
      default: ""
    },
    token: {
      type: String,
      default: ""
    },
    apiBaseUrl: {
      type: String,
      default: ""
    },
    platform: {
      type: String,
      default: ""
    },
    loginUrl: {
      type: String,
      default: "https://my.medel.com"
    },
    // External application can supply a username, if we do not ahve a valid accesstoken, but the user is logged in.
    // This should be handled correctly, and is only an intermediate solution.
    username: {
      type: String,
      default: ""
    }
  },

  components: {
    SimpleSpinner,
    ServiceDropdown
  },

  data() {
    return {
      initiallyLoaded: false,
      isDropdownOpen: false,
      isMenuOpen: false,
      isLoading: true,
      loadedUsername: false,
      isMounted: false,
      // Assuming a claim represents a service when the claim contains: my.medel.com/services/{service_id} and no trailing characters
      serviceSettings: [],
      serviceClaims: [],
      samUrl: ""
    };
  },

  computed: {
    mainItem() {
      return {
        icon: this.services.length ? this.services[0].icon : "icon-menu",
        title: this.services.length ? this.services[0].title : this.defaultText
      };
    },

    apiUrl() {
      return this.apiBaseUrl || window.MEDEL.widget.apiUrl;
    },

    displayedUsername() {
      return this.username || this.loadedUsername || "";
    },

    myMedelUrl() {
      const envName = this.getEnvironmentName();
      switch (envName) {
        case "dev":
          return "https://my.web-dev.medel.com";
        case "test":
          return "https://my.web-test.medel.com";
        default:
          return "https://my.medel.com";
      }
    },
    services() {
      if (!this.serviceSettings || this.serviceSettings.length === 0) {
        return [];
      }

      var mappedServices = this.serviceClaims
        .map(x => {
          var serviceDefinition =
            this.serviceSettings.find(
              y => y.id.toLowerCase() === x.type.toLowerCase()
            ) || {};
          serviceDefinition.status = "active";
          return serviceDefinition;
        })
        .filter(x => !!x.id); // if the id is not in the serviceSettings, still display all other services

      addCurrentPortalToBeginningOfList(
        this.portalId,
        mappedServices,
        this.serviceSettings
      );
      return mappedServices;

      function addCurrentPortalToBeginningOfList(
        id,
        serviceList,
        serviceSettings
      ) {
        // check if portalId is set
        if (!id) {
          return;
        }
        var index = serviceList.findIndex(
          x => x.id && x.id.toLowerCase() === id.toLowerCase()
        );
        if (index === 0) {
          return;
        }

        // check if portalId is already in mapped services -> move to front of list
        if (index > 0) {
          // move item to front of list
          var temp = serviceList[index];
          serviceList.splice(index, 1);
          serviceList.unshift(temp);
          return;
        }

        // add portalId service if it exists in serviceSettings
        var definition = serviceSettings.find(y => y.id === id);
        if (definition) {
          serviceList.unshift(definition);
        }
      }
    }
  },

  watch: {
    token: {
      handler() {
        this.fetchData();
      },
      immediate: true
    },
    language: {
      handler() {
        this.fetchData(true);
      },
      immediate: true
    },
    username: {
      handler(val) {
        if (!val) {
          return;
        }

        this.isLoading = false;
        // when setting the username manually, do not load data/overwrite it.
        this.displayedUsername = val;
      },
      immediate: true
    }
  },

  created: async function() {
    // only do initial fetch of data when component is fully mounted.
    this.isMounted = true;
    await this.fetchData();
  },

  methods: {
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },

    async fetchData(languageChange) {
      if (!this.isMounted) {
        return;
      }
      if (this.initiallyLoaded && this.isLoading) return;
      if (!this.apiUrl) {
        throw new Error("Provide API Base Url Attribute!");
      }

      if (!this.token) {
        // no token, so we cannot fetch info from the API
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      // "rewrite" to use new API endpoints, becuase otherwise we'd need to reconfigure all existing clients of the widget.
      const envName = this.getEnvironmentName();
      let apiUrl = this.apiUrl;
      let userId = "me";
      try {
        const decodedToken = this.parseJwt(this.token);
        userId = encodeURIComponent(decodedToken.sub);
      } catch (decodeError) {
        console.log(
          "Could not decode token - use 'me' parameter instead of userid",
          decodeError
        );
      }
      switch (envName) {
        case "dev":
          apiUrl = "https://azeuw.api-d.medel.com/dd/mymedel/v2";
          this.samUrl = "https://azeuw.api-d.medel.com/dd/sam/v1";
          break;
        case "test":
          apiUrl = "https://azeuw.api-t.medel.com/dd/mymedel/v2";
          this.samUrl = "https://azeuw.api-t.medel.com/dd/sam/v1";
          break;
        default:
          apiUrl = "https://azeuw.api.medel.com/dd/mymedel/v2";
          this.samUrl = "https://azeuw.api.medel.com/dd/sam/v1";
          break;
      }

      try {
        var promises = [];
        if (!this.serviceSettings.length || languageChange) {
          promises.push(
            this.getServiceSettings(this.token, this.language, apiUrl)
          );
        }
        promises.push(await this.getProfileInfo(this.token, userId, apiUrl));
        promises.push(await this.getServiceClaims(this.token, userId));

        await Promise.allSettled(promises);

        this.isLoading = false;
        this.initiallyLoaded = true;
      } catch (error) {
        console.log("[Menu Widget] Error retrieving user data: ", error);
      }
    },

    async getServiceClaims(token, userId) {
      const url = `${this.samUrl}/user/${userId}/service_claims`;
      try {
        var response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data && response.data.claims) {
          this.serviceClaims = response.data.claims;
        }
      } catch (err) {
        // don't throw here, user just won't see services in menu widget
      }
    },

    async getServiceSettings(token, language, sharedProfileApiUrl) {
      if (!language) {
        language = this.language || "en";
      }

      const url = `${sharedProfileApiUrl}/services?lang=${language}`;
      try {
        var response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.serviceSettings = response.data;
      } catch (error) {
        // don't throw here, user just wont see services in menu widget
      }
    },

    async getProfileInfo(token, userId, sharedProfileApiUrl) {
      const url = `${sharedProfileApiUrl}/users/${userId}`;
      try {
        var response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.loadedUsername =
          response.data.firstName + " " + response.data.lastName;
      } catch (error) {
        // don't throw here, user just wont see user data
      }
    },

    closeDropdown() {
      this.isDropdownOpen = false;
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    closeMenu() {
      this.isMenuOpen = false;
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    getEnvironmentName() {
      // right now we do not have a better way to determine the environment we are in.
      const apiUrl = this.apiUrl;
      if (
        apiUrl.indexOf("my-medel-portal.api.web-dev.medel.com") !== -1 ||
        apiUrl.indexOf("powerapi-dev.medel.com") !== -1 ||
        apiUrl.indexOf("azeuw.api-d.medel.com") !== -1
      ) {
        return "dev";
      } else if (
        apiUrl.indexOf("my-medel-portal.api.web-test.medel.com") !== -1 ||
        apiUrl.indexOf("powerapi-test.medel.com") !== -1 ||
        apiUrl.indexOf("azeuw.api-t.medel.com") !== -1
      ) {
        return "test";
      }

      return "live";
    }
  }
};
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>
