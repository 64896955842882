<template>
  <ul class="list is-dropdown" :class="{ 'is-open': isOpen }">
    <li v-for="service in services" :key="service.id">
      <a :href="service.url" class="icon-with-text" target="_blank">
        <i class="icon is-in-dropdown" :class="service.icon" />

        {{ service.title }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ServiceDropdown",

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },

    services: {
      type: Array,
      default: () => []
    }
  }
};
</script>
