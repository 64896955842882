<template>
  <span class="SimpleSpinner is-small is-red" v-show="show">
    <span />
    <span />
    <span />
  </span>
</template>

<script>
export default {
  name: "SimpleSpinner",

  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>
